
/* 5 Columns Maket
   ========================================================================== */


.col-xs-55,
.col-sm-55,
.col-md-55,
.col-lg-55 {
    position: relative;
    min-height: 1px;
    padding-right: 0px;
    padding-left: 0px;
}
.col-xs-55 {
    width: 20%;
    float: left;
}
@media (min-width: @screen-sm) {
.col-sm-55 {
        width: 20%;
        float: left;
    }
}
@media (min-width: @screen-md) {
    .col-md-55 {
        width: 20%;
        float: left;
    }
}
@media (min-width: @screen-lg) {
    .col-lg-55 {
        width: 20%;
        float: left;
    }
}